import common from './common.json';
import scopa from './scopa.json';
import setteEMezzo from './setteEMezzo.json';
import tresette from './tresette.json';
import itPoker from './itPoker.json';
import peppa from './peppa.json';
import scala40 from './scala40.json';
import burraco from './burraco.json';
import bestia from './bestia.json';
import solitario from './solitario.json';
import trentuno from './trentuno.json';
import ginRummy from './ginRummy.json';
import cirulla from './cirulla.json';
import telesina from './telesina.json';
export default {
  common,
  scopa,
  tresette,
  itPoker,
  setteEMezzo,
  peppa,
  scala40,
  burraco,
  bestia,
  solitario,
  trentuno,
  ginRummy,
  telesina,
  cirulla,
};
