import React, { useEffect, useMemo, useState } from "react";
import { BoardCardGame, BoardGameContext, GameHelper } from "@quanticogames/gameclient-core";
import { Loading } from 'components/Loading';
import { isIOS } from "react-device-detect";
import { componentTypes } from "./componentTypeMap";
import SelectCardType from "components/SelectCardType";
import { useSearchParams } from "react-router-dom";
import TournamentInfo from "components/TournamentInfo";
import { useDispatch, useSelector } from "react-redux";
import { selectStateData, selectStateLoading, selectStateParams } from "app/selector";
import { fetchTableDetail, fetchTournamentDetail, fetchRounds, fetchMatchPlayers } from "services/history.service";
import SelectPlayer from "components/selectPlayer";
import { updateParams } from "app/actions";

interface ReplayBoardProps {
  game: string;
  onUpdate?: Function;
}

// Example usage of the dynamic component
const ReplayBoard: React.FC<ReplayBoardProps> = ({ game, onUpdate }) => {
  const loading = useSelector(selectStateLoading);
  const data = useSelector(selectStateData);
  const params = useSelector(selectStateParams);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isBO = searchParams.get("bo") === "true";
  const tourId = searchParams.get("tournament");
  const session = searchParams.get("session");
  const tableId = searchParams.get("table");
  const isCashTable = !!(session || tableId);

  const Component = isCashTable ? componentTypes[`${game}Cash`] : componentTypes[game];

  const [players, setPlayers] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [boardGame, updateBoardGame] = useState<BoardCardGame>(null);
  const [tournamentInfo, setTournamentInfo] = useState(null);
  const [tableInfo, setTableInfo] = useState(null);

  const setBoardGame = (board: BoardCardGame) => {
    if (cardType && board.cardType != cardType) {
      board.setCardType(cardType);
    }
    if (boardGame) boardGame.unmount();
    updateBoardGame(board);
  };

  const getTournamentInfo = async (id: string | number) => {
    const res = await fetchTournamentDetail(id, isBO);
    setTournamentInfo(res);
  };

  const getPlayers = async(matchId: any) => {
    const res = await fetchMatchPlayers(matchId);
    const players = res.map((p) => { 
      return { userId: p?.userId, userName: p?.username }
    });
    setPlayers(players);
      setCurrentPlayer(players[0].userId);
  }

  useEffect(() => {
    console.log(game);
    if (!params?.matchId || game != "Solitario") return;

    setPlayers([]);
    setCurrentPlayer(null);
    getPlayers(params?.matchId);
  }, [params?.matchId]);

  useEffect(() => {
    dispatch(updateParams({ ...params, playerId: currentPlayer ?? 0, interactionId: 1 }));
  }, [currentPlayer]);

  useEffect(() => {
    if (tourId) getTournamentInfo(tourId);
    else setTournamentInfo(null);
  }, [tourId]);

  const getTableInfo = async (id: string | number) => {
    const res = await fetchTableDetail(id, isBO);
    setTableInfo(res);
  };

  useEffect(() => {
    if (tableId) getTableInfo(tableId);
    else setTableInfo(null);
  }, [tableId]);

  if (!Component) {
    return <div>Unknown game: {game}</div>;
  };

  const cardTypeList = useMemo(() => {
    return (boardGame?.cardTypeList || []).map(c => {
      return { value: c.type, label: c.name }
    });
  }, [boardGame?.cardTypeList]);

  const [cardType, setCardType] = useState(null);

  useEffect(() => {
    if (cardTypeList?.length > 0) {
      setCardType(cardTypeList[0]?.value);
    }
  }, [cardTypeList]);

  useEffect(() => {
    if (cardType && boardGame) {
      boardGame.setCardType(cardType);
    }
  }, [cardType]);

  const [resizing, setResizing] = useState(false);

  const updateOrientation = () => {
    setResizing(true)
    setTimeout(() => {
      setResizing(false)
    }, 1000)
  }

  useEffect(() => {
    window.addEventListener(
      'resize',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'resize',
        updateOrientation
      )
    }
  }, [])

  const isMobile = GameHelper.isMobile();
  return (
    <div
      id="main-table"
      className={`main-table main-table-${game} py-[10px] !mt-0 flex items-center justify-start md:justify-center flex-col bg-[#114443] w-full ${game}`}
    >
      {loading && <Loading />}
      <div
        id="table-round"
        className={`table-${game} ${game} relative rounded-[999px] flex items-center justify-center ${isIOS && isMobile ? "ios-mobile" : ""}`}
      >
        {isMobile ? null : (
          <>
            <SelectCardType cardTypeList={cardTypeList} cardType={cardType} setCardType={setCardType} />
            {game === 'Solitario' && currentPlayer && <SelectPlayer listPlayer={players} player={currentPlayer} onSelectPlayer={setCurrentPlayer} />}
          </>
        )}

        <TournamentInfo tournamentInfo={tournamentInfo} cardTypeList={cardTypeList} cardType={cardType} setCardType={setCardType} players={players} currentPlayer={currentPlayer} setCurrentPlayer={setCurrentPlayer} />
        <BoardGameContext.Provider value={{ boardGame, setBoardGame }}>
          {!resizing && data && <Component
            moveData={data}
            onUpdate={onUpdate}
            matchId={params.matchId}
            interactionId={params.interactionId}
            isCashTable={!!isCashTable}
            maxSeats={tableInfo?.numberOfSeats || tournamentInfo?.maxSeats}
            currentPlayer={currentPlayer}
            players={players}
          />}
        </BoardGameContext.Provider>
      </div>
    </div>
  );
};

export default ReplayBoard;
