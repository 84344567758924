
import { useTranslation } from "react-i18next";
import { GameHelper } from "@quanticogames/gameclient-core";
import { Select, MenuItem } from "@mui/material";


type SelectCardTypeProps = {
    cardTypeList: { value: string, label: string }[]
    cardType: string
    setCardType: (cardType: string) => void
}

const SelectCardType = ({ cardTypeList, cardType, setCardType }: SelectCardTypeProps) => {
    const { t } = useTranslation();
    const isMobile = GameHelper.isMobile();

    return <>
        <div className={`${isMobile ? 'mt-[10px]' : 'fixed top-[50px] left-[50px]'} flex flex-col select-card-box`}>
            <label className={`${isMobile ? 'text-black' : 'mb-[10px] text-[#fff]'} inline-block  font-bold text-[14px]`}>{t('Displaying Deck')}</label>
            <Select
                onChange={(e) => setCardType(e.target.value)}
                className="bg-[#fff] block select-card-input cursor-pointer !border-none h-[40px] rounded-[2px] w-[200px] px-[10px] font-semibold"
                value={cardType || ''}
            >
                {cardTypeList.map(function (option, i) {
                    return <MenuItem key={i} value={option.value} className="min-h-[30px]">
                        {option.label}
                    </MenuItem>;
                })}
            </Select>
        </div></>
}
export default SelectCardType